import IGeneric from "../interfaces/IGeneric";

import "../styles/FileOutput.css";
import Label from "./Label";

export const FileOutput: React.FC = ((props: IGeneric) => {
    const { type, attributes, elementId, containerRef, files } = props;
    const filesOutput = Array.isArray(files) ? files : Object.keys(files).map(key => files[key]);

    const { label, tooltip, noWrap } = attributes;

    return (
        <div className={`input-container file-output ${noWrap}`} id={`${elementId}_container`} ref={containerRef}>
            <div>
                <div className={type}>
                    <Label
                        elementId={elementId}
                        content={label}
                        tooltip={tooltip}
                    />
                    {type === "download" && (filesOutput as IGeneric[]).map((file, index) =>
                        <a
                            href={`${file.data}&download=${encodeURIComponent(file.name)}`}
                            tabIndex={0}
                            key={`${elementId}_fileOutput_${index}`}
                        >
                            {file.name}
                        </a>
                    )}
                    {type === "display" && (filesOutput as IGeneric[]).map((file, index) =>
                        <div key={`${elementId}_file_${index}`}>
                            <button
                                type="button"
                                data-hs-overlay={`#modal_${elementId}_file_${index}`}
                                tabIndex={0}
                            >
                                {file.name}
                            </button>
                            <div
                                id={`modal_${elementId}_file_${index}`}
                                className="hs-overlay hidden"
                            >
                                <div className="hs-overlay-open:mt-7 hs-overlay-open:opacity-100 hs-overlay-open:duration-500">
                                    <div className={file.type.replace(/\//g,'-')}>
                                        <div>
                                            <h3>
                                                {file.name}
                                            </h3>
                                            <button
                                                type="button"
                                                data-hs-overlay={`#modal_${elementId}_file_${index}`}
                                            >
                                                <span>Close</span>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                                    <path d="M18 6 6 18"></path>
                                                    <path d="m6 6 12 12"></path>
                                                </svg>
                                            </button>
                                        </div>
                                        <div>
                                            <div>
                                                <object
                                                    style={file.type === "application/pdf" ? { "width": "800px", "height":"100%" } : {}}
                                                    aria-label={file.name}
                                                    type={file.type}
                                                    data={file.data}
                                                >
                                                    <div>
                                                        <a
                                                            href={`${file.data}&download=${encodeURIComponent(file.name)}`}
                                                        >
                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor">
                                                                <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3" />
                                                            </svg>
                                                        </a>
                                                    </div>
                                                </object>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
});

export default FileOutput;
