import IGeneric from "../interfaces/IGeneric";

import "../styles/AddressSearch.css";
import Label from "./Label";

import { handleKeyDown, handleKeyUp } from "../utils/validators";

import React, { useEffect, useState } from "react";
import { APIProvider, useMapsLibrary } from "@vis.gl/react-google-maps";

const AddressSearchComponent: React.FC = (props: IGeneric) => {

    const { attributes, elementId, handleAddressChange, onEnter, inputRef } = props;

    const { placeholder } = attributes;

    const places = useMapsLibrary("places");

    const [selectedPlace, setSelectedPlace] = useState<google.maps.places.PlaceResult | null>(null);
    const [placeAutocomplete, setPlaceAutocomplete] = useState<google.maps.places.Autocomplete | null>(null);

    useEffect(() => {
        places && inputRef.current && setPlaceAutocomplete(new places.Autocomplete(inputRef.current, {
            fields: ["name", "formatted_address", "address_components"]
        }));
    }, [places]);

    useEffect(() => {
        if (!placeAutocomplete) return;

        placeAutocomplete.addListener("place_changed", () => {
            handleAddressChange(props, placeAutocomplete.getPlace().address_components);
            setSelectedPlace(placeAutocomplete.getPlace());
        });
    }, [selectedPlace, placeAutocomplete]);

    return (
        <input
            id={elementId}
            type="text"
            inputMode="search"
            placeholder={placeholder}
            ref={inputRef}
            autoComplete="off"
            onKeyDown={handleKeyDown}
            onKeyUp={(event) => handleKeyUp(event, onEnter)}
            tabIndex={0}
        />
    );
};
const AddressSearch: React.FC = (props: IGeneric) => {

    const { attributes, elementId, containerRef } = props;

    const { label, tooltip, provider, apiKey, countryLimitCode, language, noWrap } = attributes;

    const [isLoaded, setIsLoaded] = useState<boolean>(false);

    const handleLoad = () => {
        setIsLoaded(true);
    }

    return (
        <div className={`input-container address-search ${noWrap}`} id={`${elementId}_container`} ref={containerRef}>
            <div>
                <div>
                    <Label
                        elementId={elementId}
                        content={label}
                        tooltip={tooltip}
                    />
                    {provider === "Google Places API" && apiKey &&
                        <APIProvider onLoad={handleLoad} libraries={["places"]} apiKey={apiKey} language={language} region={countryLimitCode}>
                            <AddressSearchComponent {...props} />
                        </APIProvider>
                    }
                </div>
            </div>
        </div>
    );
};
export default AddressSearch;

