import IMenuProps from "../interfaces/IMenuProps";
import IMenuItem from "../interfaces/IMenuItem";

import "../styles/Menu.css";

import MenuItem from "./MenuItem";

import React from "react";

export const Menu: React.FC<IMenuProps> = ({
    type,
    header,
    menuItems,
    menuItemCallback
}) => {
    return (
        <>
            {type === "top" &&
                <nav
                    className={`menu-${type}`}
                >
                    <div className="menu-toggle-1">
                        <div className="menu-toggle-2">
                            <div className="menu-toggle-3">
                                <button
                                    type="button"
                                    className="hs-collapse-toggle"
                                    id={`hs-navbar-${type}-collapse`}
                                    aria-expanded="false"
                                    aria-controls={`hs-navbar-${type}`}
                                    data-hs-collapse={`#hs-navbar-${type}`}
                                >
                                    <svg
                                        className="hs-collapse-open:hidden"
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    >
                                        <line x1="3" x2="21" y1="6" y2="6" />
                                        <line x1="3" x2="21" y1="12" y2="12" />
                                        <line x1="3" x2="21" y1="18" y2="18" />
                                    </svg>
                                    <svg
                                        className="hs-collapse-open:block hidden"
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    >
                                        <path d="M18 6 6 18" />
                                        <path d="m6 6 12 12" />
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div
                        id={`hs-navbar-${type}`}
                        className="hs-collapse hidden"
                        aria-labelledby={`hs-navbar-${type}-collapse`}
                    >
                        <div className="menu-items">
                            {
                                menuItems.map((menuItem: IMenuItem, index: number) =>
                                    <MenuItem
                                        title={menuItem.title}
                                        trigger={menuItem.trigger}
                                        reInitialise={menuItem.reInitialise}
                                        icon={menuItem.icon}
                                        subMenuItems={menuItem.subMenuItems}
                                        menuItemCallback={menuItemCallback}
                                        key={`${type}Menu_${index}`}
                                        id={`${type}Menu_${index}`}
                                    />
                                )
                            }
                        </div>
                    </div>
                </nav>
            }
            {type === "side" &&
                <div
                    className={`menu-${type}`}
                >
                    <button type="button" className="open-button" aria-haspopup="dialog" aria-expanded="false" aria-controls="menu-side" aria-label="Toggle navigation" data-hs-overlay="#menu-side">
                        <svg
                            className="side-menu-pullout"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        >
                            <path d="m6 9 6 6 6-6" />
                        </svg>
                    </button>
                    <div
                        id="menu-side"
                        className="hs-overlay hs-overlay-open:translate-x-0 translate-x-full transition-all hidden"
                        role="dialog"
                        aria-label="Sidebar"
                    >
                        <button type="button" className="close-button" aria-haspopup="dialog" aria-expanded="false" aria-controls="menu-side" aria-label="Toggle navigation" data-hs-overlay="#menu-side">
                            <svg
                                className="hs-collapse-open:block"
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            >
                                <path d="M18 6 6 18" />
                                <path d="m6 6 12 12" />
                            </svg>
                        </button>
                        {
                            menuItems.map((menuItem: IMenuItem, index: number) =>
                                <MenuItem
                                    title={menuItem.title}
                                    trigger={menuItem.trigger}
                                    reInitialise={menuItem.reInitialise}
                                    icon={menuItem.icon}
                                    subMenuItems={menuItem.subMenuItems}
                                    menuItemCallback={menuItemCallback}
                                    key={`${type}Menu_${index}`}
                                    id={`${type}Menu_${index}`}
                                />
                            )
                        }
                    </div>
                </div>
            }
        </>
    );
};

export default Menu;
