import IGeneric from "../interfaces/IGeneric";

import React, { useEffect } from "react";

export const HiddenInput: React.FC = (props: IGeneric) => {
    const { type, attributes, elementId, onStateChange } = props;

    const { value } = attributes;

    useEffect(() => {
        onStateChange(value);
        // eslint-disable-next-line
    }, []);

    return (
        <input
            key={elementId}
            id={elementId}
            type={type}
            defaultValue={value}
        />
    );
};

export default HiddenInput;
