import IClientAppConfig from "../interfaces/IClientAppConfig";
import axios from "axios";
export class ClientAppConfig implements IClientAppConfig {
    protected apiVersion: string | undefined;
    protected apiDomain: string | undefined;

    public client: string = "";
    public clientAppName: string = "";
    public clientAppDomain: string = "";
    public environment: string = "";
    public tenantId: string = "";
    public subscriptionId: string = "";
    public ssoAppId: string = "";
    public workflowResourcegroupName: string = "";
    public workflowName: string = "";
    public stateless: boolean = false;
    public requiresAuthentication: boolean = false;

    protected async getClientAppConfig(): Promise<IClientAppConfig> {
        const response = await axios({
            url: `${this.apiDomain ? `https://${this.apiDomain}` : ``}/api/ui/config/?version=${this.apiVersion}`,
            method: "GET",
        });
        const { client, clientAppName, clientAppDomain, environment, tenantId, subscriptionId, ssoAppId, workflowResourcegroupName, workflowName, stateless, requiresAuthentication, } = response.data;
        const clientAppConfig: IClientAppConfig = {
            client, clientAppName, clientAppDomain, environment, tenantId, subscriptionId, ssoAppId, workflowResourcegroupName, workflowName, stateless, requiresAuthentication,
        };
        return clientAppConfig;
    }
    public constructor(apiVersion: string | undefined, apiDomain: string | undefined) {
        this.apiVersion = apiVersion;
        this.apiDomain = apiDomain;
    }

    async initialize(): Promise<void> {
        await this.getClientAppConfig().then((config) => {
            this.client = config.client;
            this.clientAppName = config.clientAppName;
            this.clientAppDomain = config.clientAppDomain;
            this.environment = config.environment;
            this.tenantId = config.tenantId;
            this.subscriptionId = config.subscriptionId;
            this.ssoAppId = config.ssoAppId;
            this.workflowResourcegroupName = config.workflowResourcegroupName;
            this.workflowName = config.workflowName;
            this.stateless = config.stateless;
            this.requiresAuthentication = config.requiresAuthentication;
        });
        return;
    }
}
