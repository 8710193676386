import IGeneric  from "../interfaces/IGeneric";

import "../styles/Title.css";

export const Title = (props: IGeneric) => {
    const { elementId, title } = props;

    return (
        <h1 className="title nowrap" id={elementId} key={elementId} dangerouslySetInnerHTML={{ __html: title }} />
    )
};

export default Title;
