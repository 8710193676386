import IGeneric from "../interfaces/IGeneric";

import "../styles/Table.css";
import Label from "./Label";

import { handleKeyDown, handleKeyUp, validator } from "../utils/validators";

import React, { useEffect, useState } from "react";

export const Table: React.FC = (props: IGeneric) => {
    const { type, attributes, elementId, onStateChange, validations, journeyTriggerCallback, onError, error, tableColumns, tableData, actionColumns, containerRef } = props;

    const { label, value, tooltip, editable, addable, deleteable, searchable, noWrap } = attributes;

    const [inputValue, setInputValue] = useState<IGeneric[]>(value ?? tableData);
    const [searchValue, setSearchValue] = useState<string | null>(null);
    const [filteredTableData, setFilteredTableData] = useState<IGeneric[]>(inputValue);
    const [sortColumns, setSortColumns] = useState<IGeneric[]>(tableColumns);
    const [newRow, setNewRow] = useState<IGeneric>({});

    const validate = (value: IGeneric[], showError: boolean) => {
        const validationMessage = validator(
            value,
            type,
            validations,
            attributes,
        );

        if (validationMessage)
            onError({ validationMessage, showError });
        else {
            onStateChange(value);
            setInputValue(value);
            onError(undefined);
        }
    };

    const handleActionClick = (event: React.MouseEventHandler<HTMLButtonElement> | undefined, row: IGeneric, actionColumn: IGeneric): void => {
        const { journey, action } = actionColumn;
        if (journey) {
            const journeyData: IGeneric = {};
            const { data, name } = journey;
            (data && data as IGeneric[]).forEach((element: IGeneric) => {
                let newValue = element.value;
                const dataMatch = newValue.match(new RegExp(/(\{{[^{}]*\}})/g));
                if (dataMatch !== undefined && dataMatch.length > 0) {
                    dataMatch.forEach((matchElement: string) => {
                        newValue = newValue.replace(
                            matchElement,
                            row[`${matchElement.substring(2, matchElement.length - 2)}`],
                        );
                    });
                }
                journeyData[`${element.name}`] = newValue;
            });
            journeyTriggerCallback({ name, journeyData });
        }
        else if (action) {
            const matches = action.match(new RegExp(/(\{{[^{}]*\}})/g));
            let actionLink = action;
            matches?.forEach((element: IGeneric) => {
                actionLink = actionLink.replace(element, row[`${element.substring(2, element.length - 2)}`]);
            });
            window.open(actionLink, "_blank");
        }
    }

    const handleSortClick = (event: React.MouseEventHandler<HTMLButtonElement> | undefined, column: IGeneric): void => {
        const columnName = column.dataIndex as string;
        if (!column.sortOrder || column.sortOrder > 0)
            column.sortOrder = -1;
        else
            column.sortOrder = 1;
        const sortedTableData = filteredTableData && filteredTableData.sort((a: IGeneric, b: IGeneric) => a[columnName] > b[columnName] ? column.sortOrder : -column.sortOrder).map((sorted: IGeneric) => sorted);
        setSortColumns(sortColumns);
        setFilteredTableData(sortedTableData);
    }

    const handleEditClick = (event: React.MouseEventHandler<HTMLButtonElement> | undefined, row: IGeneric): void => {
        const editableTable = inputValue && inputValue.map((editRow: IGeneric) => {
            if (editRow === row) {
                editRow.editable = false;
                editRow.edit = true;
            }
            return editRow;
        });
        setInputValue(editableTable);
        search(editableTable, searchValue);
    }

    const firstRow: IGeneric = filteredTableData && filteredTableData.length > 0 ? filteredTableData[0] : {};
    const handleEditBlur = (event: React.ChangeEvent<HTMLInputElement>, row: IGeneric | null, column: IGeneric): void => {
        const newValue = typeof (firstRow && firstRow[`${column.dataIndex}`]) === "number" && event.target.value ? parseFloat(event.target.value) : event.target.value;
        const editedTable = inputValue && inputValue.map((editedRow: IGeneric) => {
            if (editedRow === row)
                editedRow[`${column.dataIndex}`] = newValue;
            return editedRow;
        });
        if (row === null) {
            newRow[`${column.dataIndex}`] = newValue;
            if (sortColumns && sortColumns.findIndex((col: IGeneric) => col === column) === (sortColumns.length - 1)) {
                editedTable.push(newRow);
                setNewRow({});
            }
        }
        setInputValue(editedTable);
        validate(editedTable, true);
        search(editedTable, searchValue);
    }

    const handleDeleteClick = (event: React.MouseEventHandler<HTMLButtonElement> | undefined, row: IGeneric): void => {
        const deletedTableData = inputValue && inputValue.filter((data: IGeneric) => data !== row);
        validate(deletedTableData, true);
        search(deletedTableData, searchValue);
    }

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>): void => {
        search(inputValue, event.target.value);
        setSearchValue(event.target.value);
    }

    const search = (searchTableData: IGeneric[], searchText: string | null): void => {
        if (searchText != null && searchText.length > 0)
            setFilteredTableData(searchTableData.filter((row: IGeneric) => {
                let found = false;
                (tableColumns && tableColumns as IGeneric[]).forEach((column: IGeneric) => {
                    if (row[`${column.dataIndex}`].toString().includes(searchText))
                        found = true;
                });
                return found;
            }));
        else
            setFilteredTableData(searchTableData);
    }

    useEffect(() => {
        validate(inputValue, false);
        // eslint-disable-next-line
    }, []);

    return (
        <div className={`input-container table-container ${noWrap}`} id={`${elementId}_container`} ref={containerRef}>
            <div>
                <div>
                    <Label
                        elementId={elementId}
                        content={label}
                        tooltip={tooltip}
                    />
                    <div>
                        <div>
                            <div>
                                <div>
                                    {searchable === true &&
                                        <div className="search">
                                            <div>
                                                <label>Search</label>
                                                <input
                                                    type="text"
                                                    name="hs-table-with-pagination-search"
                                                    id="hs-table-with-pagination-search"
                                                    placeholder="Quick search"
                                                    onChange={handleSearch}
                                                    onKeyDown={handleKeyDown}
                                                />
                                                <div>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                                        <circle cx="11" cy="11" r="8"></circle>
                                                        <path d="m21 21-4.3-4.3"></path>
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    <table>
                                        <thead>
                                            <tr>
                                                {
                                                    sortColumns && sortColumns.map((column: IGeneric, columnIndex: number) => {
                                                        return (
                                                            <th
                                                                key={`${elementId}_column_${columnIndex}`}
                                                                scope="col"
                                                            >
                                                                {
                                                                    column.sortable ?
                                                                        <button
                                                                            type="button"
                                                                            onClick={() => handleSortClick(this, column)}
                                                                        >
                                                                            <span>
                                                                                {column.title}
                                                                            </span>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                                                                <path d="m7 15 5 5 5-5" />
                                                                                <path d="m7 9 5-5 5 5" />
                                                                            </svg>
                                                                        </button>
                                                                        :
                                                                        <span>
                                                                            {column.title}
                                                                        </span>
                                                                }
                                                            </th>
                                                        )
                                                    })
                                                }
                                                {
                                                    actionColumns && actionColumns.map((column: IGeneric, actionColumnIndex: number) => {
                                                        return (
                                                            <th
                                                                key={`${elementId}_action_column_${actionColumnIndex}`}
                                                                scope="col"
                                                            >
                                                                {column.title}
                                                            </th>
                                                        )
                                                    })
                                                }
                                                {
                                                    deleteable &&
                                                    <th
                                                        key={`${elementId}_delete_column`}
                                                        scope="col"
                                                    >
                                                    </th>
                                                }
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                filteredTableData && filteredTableData.map((row: IGeneric, rowIndex: number) => {
                                                    return (
                                                        <tr
                                                            key={`${elementId}_row_${rowIndex}`}
                                                        >
                                                            {
                                                                tableColumns && tableColumns.map((column: IGeneric, rowColumnIndex: number) => {
                                                                    return (
                                                                        <td
                                                                            key={`${elementId}_row_${rowIndex}_column_${rowColumnIndex}`}
                                                                        >
                                                                            {column.editable === true && row.edit === true ?
                                                                                <input
                                                                                    type={typeof (row[`${column.dataIndex}`]) === "number" ? "number" : "text"}
                                                                                    defaultValue={row[`${column.dataIndex}`]}
                                                                                    name={`${elementId}_row_${rowIndex}_column_${rowColumnIndex}`}
                                                                                    onBlur={(event: React.ChangeEvent<HTMLInputElement>) => handleEditBlur(event, row, column)}
                                                                                    onKeyDown={handleKeyDown}
                                                                                />
                                                                                :
                                                                                <span>{row[`${column.dataIndex}`]}</span>
                                                                            }
                                                                        </td>
                                                                    )
                                                                })
                                                            }
                                                            {
                                                                actionColumns && actionColumns.map((column: IGeneric, rowActionColumnIndex: number) => {
                                                                    let newValue = column.text ?? column.title;
                                                                    const dataMatch = newValue.match(new RegExp(/(\{{[^{}]*\}})/g));
                                                                    if (dataMatch?.length !== undefined && dataMatch.length > 0) {
                                                                        dataMatch.forEach((matchElement: string) => {
                                                                            newValue = newValue.replace(
                                                                                matchElement,
                                                                                row[`${matchElement.substring(2, matchElement.length - 2)}`] ?? "",
                                                                            );
                                                                        });
                                                                    }
                                                                    return (
                                                                        <td
                                                                            key={`${elementId}_action_row_${rowIndex}_column_${rowActionColumnIndex}`}
                                                                            className="action"
                                                                        >
                                                                            <button
                                                                                type="button"
                                                                                onClick={() => handleActionClick(this, row, column)}
                                                                            >
                                                                                {newValue}
                                                                            </button>
                                                                        </td>
                                                                    )
                                                                })
                                                            }
                                                            {
                                                                (editable === true || deleteable === true) &&
                                                                <td
                                                                    key={`${elementId}_delete_row_${rowIndex}_column`}
                                                                    className="edit-menu"
                                                                >
                                                                    <div>
                                                                        <div className="hs-dropdown">
                                                                            <button
                                                                                id={`hs-table-dropdown-${rowIndex}`}
                                                                                type="button"
                                                                                className="hs-dropdown-toggle"
                                                                            >
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><circle cx="12" cy="12" r="1" /><circle cx="19" cy="12" r="1" /><circle cx="5" cy="12" r="1" /></svg>
                                                                            </button>
                                                                            <div
                                                                                className="hs-dropdown-menu hs-dropdown-open:opacity-100 transition-[opacity,margin] opacity-0 hidden divide-y"
                                                                                aria-labelledby={`hs-table-dropdown-${rowIndex}`}
                                                                            >
                                                                                {editable === true && row.editable !== false &&
                                                                                    <div className="edit">
                                                                                        <button
                                                                                            type="button"
                                                                                            onClick={() => handleEditClick(this, row)}
                                                                                        >
                                                                                            Edit
                                                                                        </button>
                                                                                    </div>
                                                                                }
                                                                                {deleteable === true &&
                                                                                    <div className="delete">
                                                                                        <button
                                                                                            type="button"
                                                                                            onClick={() => handleDeleteClick(this, row)}
                                                                                        >
                                                                                            Delete
                                                                                        </button>
                                                                                    </div>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            }
                                                        </tr>
                                                    )
                                                })
                                            }
                                            {
                                                addable === true &&
                                                <tr
                                                    key={`${elementId}_row_new`}
                                                    className="new"
                                                >
                                                    {
                                                        tableColumns && tableColumns.map((column: IGeneric, newRowColumnIndex: number) => {
                                                            return (
                                                                <td
                                                                    key={`${elementId}_row_new_column_${newRowColumnIndex}`}
                                                                >
                                                                    <input
                                                                        type={typeof (firstRow[`${column.dataIndex}`]) === "number" ? "number" : "text"}
                                                                        defaultValue={column.newRowDefault}
                                                                        name={`${elementId}_row_new_column_${newRowColumnIndex}`}
                                                                        onBlur={(event: React.ChangeEvent<HTMLInputElement>) => handleEditBlur(event, null, column)}
                                                                        onKeyDown={handleKeyDown}
                                                                    />
                                                                </td>
                                                            )
                                                        })
                                                    }
                                                    {
                                                        actionColumns && actionColumns.map((column: IGeneric, newRowActionColumnIndex: number) => {
                                                            return (
                                                                <td
                                                                    key={`${elementId}_action_row_new_column_${newRowActionColumnIndex}`}
                                                                    className="action"
                                                                >
                                                                </td>
                                                            )
                                                        })
                                                    }
                                                    {
                                                        (editable === true || deleteable === true) &&
                                                        <td
                                                            key={`${elementId}_delete_row_new_column`}
                                                            className="edit-delete"
                                                        >
                                                        </td>
                                                    }
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <p
                        id={`${elementId}_error`}
                        className="error"
                    >
                        {error && error.show && error.validationMessage}
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Table;
