export const LoadingPulse = () => {
    return (
        <div className="loading-pulse">
            <div>
                <div></div>
                <div>
                    <div></div>
                    <div>
                        <div>
                            <div></div>
                            <div></div>
                        </div>
                        <div></div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default LoadingPulse;
