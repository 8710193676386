import IGeneric  from "../interfaces/IGeneric";

import "../styles/Image.css";

export const Image = (props: IGeneric) => {
    const { elementId, attributes } = props;
    const { href, maxWidth, maxHeight, unit, noWrap } = attributes;
    const maxWidthClass = maxWidth ? ` max-w-[${maxWidth}${unit}]` : '';
    const maxHeightClass = maxHeight ? ` max-w-[${maxHeight}${unit}]` : '';

    return (
        <div className={`input-container ${noWrap}`} id={`${elementId}_container`}>
            <img className={`image${maxWidthClass}${maxHeightClass}`} id={elementId} key={elementId} src={href} />
        </div>
    )
};

export default Image;
