import ILabelProps from "../interfaces/ILabelProps";

import React from "react";

export const Label: React.FC<ILabelProps> = ({
    elementId,
    content,
    tooltip,
    noForElement
}) => {
    return (
        <>
            {(content || tooltip) &&
                <label
                    {...(noForElement ? {} : { htmlFor: elementId })}
                    id={`${elementId}-label`}
                    className="label"
                >
                    {content &&
                        <span>{content}</span>
                    }
                    {tooltip &&
                        <div className="tooltip hs-tooltip">
                            <button type="button" className="hs-tooltip-toggle" aria-label="tooltip button" tabIndex={-1}>
                                <span className="hs-tooltip-content hs-tooltip-shown:opacity-100 hs-tooltip-shown:visible" role="tooltip">
                                    {tooltip}
                                </span>
                            </button>
                        </div>
                    }
                </label>
            }
        </>
    );
};

export default Label;
