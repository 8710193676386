import IGeneric from "../interfaces/IGeneric";
import moment from "moment";

export const isNullOrEmpty = (value: any): boolean => {
    return value === undefined || value === null || value.toString() === "";
}

export const validator = (value: string | number | IGeneric | undefined | null, type: string, validations: IGeneric, attributes: IGeneric): string | undefined => {

    const { required, requiredMessage, validationMessage } = validations;

    const stringValue = (value ?? "").toString();
    switch (type) {
        case "date":
            if (required && isNullOrEmpty(value))
                return requiredMessage ?? "Please select a date";
            const val = moment(stringValue, "YYYY-MM-DD");
            const min = moment(validations.minDate ?? "1900-01-01", "YYYY-MM-DD");
            const max = moment(validations.maxDate, "YYYY-MM-DD");

            if (!val.isBetween(min, max, "day", "[]")) {
                if (validationMessage)
                    return validationMessage;
                else if (validations.minDate && validations.maxDate)
                    return `Date must be between ${validations.minDate} and ${validations.maxDate}`;
                else if (validations.minDate)
                    return `Date must be must be on or after ${validations.minDate}`;
                else if (validations.maxDate)
                    return `Date must be on or before ${validations.maxDate}`;
            }
            break;
        case "text":
        case "textarea":
        case "tel":
            if (required && isNullOrEmpty(value))
                return requiredMessage ?? "Please enter a value";
            if (stringValue.length < (validations.minLength || 0 as number) || stringValue.length > (validations.maxLength || 9999999 as number)) {
                if (validationMessage)
                    return validationMessage;
                else if (validations.minLength && validations.maxLength)
                    return `Value must be between ${validations.minLength} and ${validations.maxLength} characters`;
                else if (validations.minLength)
                    return `Value must be more than ${validations.minLength} characters`;
                else if (validations.maxLength)
                    return `Value must be less than ${validations.maxLength} characters`;
            }
            break;
        case "email":
            if (required && isNullOrEmpty(value))
                return requiredMessage ?? "Please enter an email address";
            const emailRegex = new RegExp(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g);

            if (!emailRegex.test(stringValue)) {
                return validationMessage ?? "Please enter a valid email address";
            }
            break;
        case "number":
            if (required && isNullOrEmpty(value))
                return requiredMessage ?? "Please enter a number";
            const numValue = value as number;

            if (numValue < (validations.minValue ?? 0 as number) || numValue > (validations.maxValue ?? 0 as number)) {
                if (validationMessage)
                    return validationMessage;
                else if (validations.maxValue)
                    return `Number must be between ${validations.minValue} and ${validations.maxValue}`;
                else if (validations.minValue)
                    return `Number must be greater than or equal to ${validations.minValue}`;
                else if (validations.maxValue)
                    return `Number must be less than or equal to ${validations.maxValue}`;
            }
            break;
        case "file":
            const imageTypes = ["image/png", "image/gif", "image/jpeg"];
            const documentTypes = ["application/msword", "application/pdf"];
            const bothTypes = [...imageTypes, ...documentTypes];
            const fileObject = value as IGeneric;
            let valid = true;
            if (fileObject.size > (validations.maxSize as number * 1024))
                valid = false;
            else
                switch (validations.fileType) {
                    case "Image":
                        valid = imageTypes.includes(fileObject.type);
                        break;
                    case "Document":
                        valid = documentTypes.includes(fileObject.type);
                        break;
                    case "Both":
                        valid = bothTypes.includes(fileObject.type);
                        break;
                }
            if (!valid)
                return validationMessage;
            break;
        // TODO other inputs
    }
}

export const mutliValidator = (value: string[] | IGeneric[] | undefined | null, type: string, validations: IGeneric, attributes: IGeneric): string | undefined => {
    if (validations.required && (value === null || value === undefined || value.length === 0))
        switch (type) {
            case "radio":
            case "button-radio":
                return validations.requiredMessage ?? "An option must be selected";
            case "select":
            case "checkbox":
                return `${validations.requiredMessage ?? ((attributes.multiple ?? false) ? "At least one" : "An")} option must be selected`;
            case "file":
                return `${validations.requiredMessage ?? ((attributes.multiple ?? false) ? "At least one" : "A")} file must be selected`;
        }
    else {
        return undefined;
    }

    for (let item of value ?? []) {
        const validationMessage = validator(item, type, validations, attributes);
        if (validationMessage) return validationMessage;
    }
}

export const handleKeyDown = (event: React.KeyboardEvent): void => {
    if (event.key === "Enter") {
        event.stopPropagation();
        event.preventDefault();
    }
}

export const handleKeyUp = (event: React.KeyboardEvent, onEnter: () => void) => {
    const newEvent = event.target as IGeneric;
    if (event.key === "Enter") {
        event.stopPropagation();
        event.preventDefault();
        onEnter();
    }
}
