import IGeneric from "../interfaces/IGeneric";

import "../styles/Text.css";
import { getFormattedString } from "../utils/mutate";

export const Text = (props: IGeneric) => {
    const { elementId, attributes } = props;
    const { noWrap } = attributes;
    const html = getFormattedString(props) ?? "";

    return (
        <div className={`text ${noWrap}`} id={elementId} key={elementId} dangerouslySetInnerHTML={{ __html: html }} />
    )
};

export default Text;
