import IGeneric from "../interfaces/IGeneric";

import "../styles/Widget.css";
import Label from "./Label";

import React from "react";

export const Widget: React.FC = ((props: IGeneric) => {
    const { attributes, elementId, onStateChange, containerRef, inputRef, widgets } = props;

    const { value, label, tooltip } = attributes;

    return (
        <div className="input-container widget-output nowrap" id={`${elementId}_container`} ref={containerRef}>
            <div>
                <div>
                    <Label
                        elementId={elementId}
                        content={label}
                        tooltip={tooltip}
                    />
                    <div>
                        {widgets && widgets.map((widget: IGeneric, index: number) => {
                            return (
                                <div
                                    className={`widget-card${value === widget.title ? " active" : ""}`}
                                    key={`${elementId}_widget_card_${index}`}
                                    ref={inputRef}
                                    tabIndex={0}
                                    onClick={() => onStateChange(widget.title)}
                                >
                                    <div className="widget-card-head">{widget.title}</div>
                                    <div className="widget-card-body" dangerouslySetInnerHTML={{ __html: widget.value }}/>
                                </div>
                            );
                        }) }
                    </div>
                </div>
            </div>
        </div>
    );
});

export default Widget;
