import IPanelProps from "../interfaces/IPanelProps";

import React from "react";

export const Panel: React.FC<IPanelProps> = ({
    children,
    classes,
    id,
    panelRef
}) => {

    return (
        <div id={id} className={classes} ref={panelRef}>
            {children}
        </div>
    );
};

export default Panel;
