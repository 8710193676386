import IGeneric from "../interfaces/IGeneric";

import "../styles/RadioInput.css";
import Label from "./Label";

import { handleKeyDown, handleKeyUp, mutliValidator } from "../utils/validators";
import { getCurrentOptions } from "../utils/mutate";

import React, { useEffect, useState } from "react";

export const RadioInput: React.FC = ((props: IGeneric) => {
    const { type, attributes, elementId, onStateChange, validations, onError, onEnter, error, options, containerRef, inputRef } = props;

    const { value, label, tooltip, orientation, noWrap } = attributes;

    const defaultValues: string[] | undefined | null = options.filter((option: IGeneric) => option.default).map((option: IGeneric) => option.value);

    const [inputValue, setInputValue] = useState<string[] | undefined | null>(value !== undefined ? value : defaultValues);

    const validate = (selectedValues: string[] | undefined | null, show: boolean) => {
        const validationMessage = mutliValidator(
            selectedValues,
            type,
            validations,
            attributes,
        );

        if (validationMessage)
            onError({ validationMessage, show });
        else {
            if (Array.isArray(selectedValues) && !attributes.multiple)
                onStateChange(selectedValues.length > 0 ? selectedValues.at(0) : null);
            else
                onStateChange(selectedValues);
            setInputValue(selectedValues);
            onError(undefined);
        }
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => validate(event.target.value ? [event.target.value] : undefined, true);

    useEffect(() => {
        validate(inputValue, false);
        // eslint-disable-next-line
    }, []);

    return (
        <div className={`input-container radio-input ${noWrap}`} id={`${elementId}_container`} ref={containerRef}>
            <div>
                <div>
                    <Label
                        elementId={elementId}
                        content={label}
                        tooltip={tooltip}
                        noForElement={true}
                    />
                    <div className={`${type} ${orientation ?? ""}`}>
                        {type === "radio" ?
                            getCurrentOptions(options, inputValue).map((option, index) => {
                                return (
                                    <div
                                        className="option"
                                        role="none"
                                        key={`${elementId}_${option.value}_container`}
                                    >
                                        <input
                                            type="radio"
                                            key={elementId + "_" + option.value}
                                            id={elementId + "_" + option.value}
                                            name={elementId}
                                            value={option.value}
                                            defaultChecked={option.checked}
                                            onChange={handleChange}
                                            onKeyDown={handleKeyDown}
                                            onKeyUp={(event) => handleKeyUp(event, onEnter)}
                                            ref={index === 0 ? inputRef : undefined}
                                            tabIndex={0}
                                        />
                                        <label
                                            htmlFor={elementId + "_" + option.value}
                                            key={`${elementId}_${option.value}_label`}
                                        >
                                            {option.title}
                                        </label>
                                    </div>
                                )
                            })
                            :
                            getCurrentOptions(options, inputValue).map((option, index) => {
                                return (
                                    <div
                                        className="option"
                                        role="none"
                                        key={`${elementId}_${option.value}_container`}
                                    >
                                        <input
                                            type="radio"
                                            key={`${elementId}_${option.value}`}
                                            id={`${elementId}_${option.value}`}
                                            name={elementId}
                                            radioGroup={elementId}
                                            value={option.value}
                                            defaultChecked={option.checked}
                                            onChange={handleChange}
                                            onKeyDown={handleKeyDown}
                                            onKeyUp={(event) => handleKeyUp(event, onEnter)}
                                            ref={index === 0 ? inputRef : undefined}
                                            tabIndex={0}
                                        />
                                        <label
                                            htmlFor={elementId + "_" + option.value}
                                            key={`${elementId}_${option.value}_label`}
                                        >
                                            <span>{option.title}</span>
                                        </label>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <p
                        id={`${elementId}_error`}
                        className="error"
                    >
                        {error && error.show && error.validationMessage}
                    </p>
                </div>
            </div>
        </div>
    );
});

export default RadioInput;
