import IGeneric from "../interfaces/IGeneric";
import IForm from "../interfaces/IForm";
import IMenuItem from "../interfaces/IMenuItem";
import IJourneyResponse from "../interfaces/IJourneyResponse";
import IHeaders from "../interfaces/IHeaders";

import { sendPrinsixRequest } from "./PrinSIX";

export const getJourneyResponse = async (
    updateHeaders: (result: IHeaders) => void,
    headerState: IHeaders,
    currentForm: IForm | undefined,
    formData: IGeneric | undefined,
    resumeHeader: IGeneric | undefined,
    menuItem: IMenuItem | undefined,
): Promise<IJourneyResponse> => {

    const apiResponse = await sendPrinsixRequest(
        updateHeaders,
        headerState,
        currentForm,
        formData,
        resumeHeader,
        menuItem,
    );

    return apiResponse.journeyResponse;
}
