import { clientAppConfig } from "../pages/AuthProvider";
import { clearCookies } from "../service/PrinSIX";

import ILoginProps from "../interfaces/ILoginProps";
import "../styles/Login.css";

import React, { useEffect } from "react";
import { PopupRequest, InteractionStatus } from "@azure/msal-browser";
import { useMsal, useIsAuthenticated } from "@azure/msal-react";

export const Login: React.FC<ILoginProps> = ({
    menusCallback,
    loginCallback
}) => {

    const { clientAppName } = clientAppConfig;
    const { instance, inProgress } = useMsal();
    const activeAccount = instance.getActiveAccount();
    const isLoggedIn = useIsAuthenticated() && activeAccount !== null;

    const handleMouseLogin = (event: React.MouseEvent) => {
        event.preventDefault();
        if (event.button === 0)
            login();
    };

    const handleKeyLogin = (event: React.KeyboardEvent) => {
        if (event.key === "Enter") {
            event.stopPropagation();
            event.preventDefault()
            login();
        }
    }

    const handleMouseLogout = (event: React.MouseEvent) => {
        event.preventDefault();
        if (event.button === 0)
            logout();
    };

    const handleKeyLogout = (event: React.KeyboardEvent) => {
        if (event.key === "Enter") {
            event.stopPropagation();
            event.preventDefault()
            logout();
        }
    }

    const handleKeyDown = (event: React.KeyboardEvent): void => {
        if (event.key === "Enter") {
            event.preventDefault();
        }
    }

    const login = async () => {
        try {
            const request: PopupRequest = {
                state: `${window.location.protocol}//${window.location.host}/#`,
                scopes: ["openid", "profile", "user.read"],
            };
            await instance.loginPopup(request);
        }
        catch (error) {
            console.log(error);
        }
    };

    const logout = async () => {
        clearCookies();
        menusCallback(undefined);
        loginCallback(false);
        await instance.logoutPopup().then(async () => {
            await instance.clearCache().then(() => {
                instance.setActiveAccount(null);
            })
        });
    };

    useEffect(() => {
        isLoggedIn && loginCallback(isLoggedIn);
        // eslint-disable-next-line
    });

    return (
        <div className="login">
            {isLoggedIn ?
                <>
                    <div>
                        <div>{activeAccount?.name} ({activeAccount?.username})</div>
                        <div>{clientAppName}</div>
                    </div>
                    <button
                        type="button"
                        onMouseUp={handleMouseLogout}
                        onKeyUp={handleKeyLogout}
                        onKeyDown={handleKeyDown}
                    >
                        Sign Out
                    </button>
                </>
                : (inProgress !== InteractionStatus.Startup && inProgress !== InteractionStatus.HandleRedirect) ?
                    <button
                        type="button"
                        onMouseUp={handleMouseLogin}
                        onKeyUp={handleKeyLogin}
                        onKeyDown={handleKeyDown}
                        autoFocus={true}
                    >
                        Sign In
                    </button>
                    : <></>
            }
        </div>
    );
};

export default Login;
