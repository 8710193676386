import IGeneric from "../interfaces/IGeneric";

import "../styles/CheckboxInput.css";
import Label from "./Label";

import { handleKeyDown, handleKeyUp, mutliValidator } from "../utils/validators";
import { getCurrentOptions } from "../utils/mutate";

import React, { useEffect, useState } from "react";

export const CheckboxInput: React.FC = ((props: IGeneric) => {
    const { type, attributes, elementId, onStateChange, validations, onError, onEnter, error, options, containerRef, inputRef } = props;

    const { value, label, tooltip, checkAll: checkAllItems, checkAllLabel, orientation, noWrap } = attributes;

    const defaultValues: string[] | undefined | null = options.filter((option: IGeneric) => option.default).map((option: IGeneric) => option.value);

    const [inputValue, setInputValue] = useState<string[] | undefined | null>(value !== undefined ? value : defaultValues);

    function validate(selectedValues: string[] | undefined | null, show: boolean) {
        const validationMessage = mutliValidator(
            selectedValues,
            type,
            validations,
            attributes
        );

        if (validationMessage)
            onError({ validationMessage, show });
        else {
            if (Array.isArray(selectedValues) && !attributes.multiple)
                onStateChange(selectedValues.length > 0 ? selectedValues.at(0) : null);
            else
                onStateChange(selectedValues);
            setInputValue(selectedValues);
            onError(undefined);
        }
    }

    const checkAll = inputRef.current;
    const elements = document.getElementsByName(elementId);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let selectedValues: string[] | undefined | null;
        elements.forEach(element => {
            const checkbox: HTMLInputElement = element as HTMLInputElement;
            if (checkbox.checked) {
                if (!selectedValues)
                    selectedValues = [];
                selectedValues.push(checkbox.value);
            }
        })
        if (checkAllItems) {
            if (selectedValues && selectedValues.length === elements.length) {
                checkAll && (checkAll.indeterminate = false);
                checkAll && (checkAll.checked = true);
            }
            else if (selectedValues && selectedValues.length > 0) checkAll.indeterminate = true;
            else {
                checkAll && (checkAll.checked = false);
                checkAll && (checkAll.indeterminate = false);
            }
        }
        validate(selectedValues, true);
    }

    const handleCheckAll = (event: React.ChangeEvent<HTMLInputElement>) => {
        elements.forEach(element => {
            const checkbox: HTMLInputElement = element as HTMLInputElement;
            checkbox.checked = checkAll.checked;
        })
        handleChange(event);
    }

    useEffect(() => {
        validate(inputValue, false);
        // eslint-disable-next-line
    }, []);

    return (
        <div className={`input-container checkbox-input ${noWrap}`} id={`${elementId}_container`} ref={containerRef}>
            <div>
                <div>
                    <Label
                        elementId={elementId}
                        content={label}
                        tooltip={tooltip}
                        noForElement={true}
                    />
                    <div className={orientation ?? ""}>
                        {
                            checkAllItems && (
                                <>
                                    <div className="option">
                                        <input
                                            type="checkbox"
                                            key={`${elementId}_checkAll`}
                                            id={`${elementId}_checkAll`}
                                            value={undefined}
                                            onChange={handleCheckAll}
                                            onKeyDown={handleKeyDown}
                                            onKeyUp={(event) => handleKeyUp(event, onEnter)}
                                            ref={inputRef}
                                            tabIndex={0}
                                        />
                                        <label htmlFor={`${elementId}_checkAll`}>{checkAllLabel ?? "Check All"}</label>
                                    </div>
                                    <hr />
                                </>
                            )
                        }
                        {
                            getCurrentOptions(options, inputValue).map((option: IGeneric, index) => {
                                return (
                                    <div className="option" key={`${elementId}_${option.value}_container`}>
                                        <input
                                            type="checkbox"
                                            key={`${elementId}_${option.value}`}
                                            id={`${elementId}_${option.value}`}
                                            name={elementId}
                                            value={option.value}
                                            defaultChecked={option.checked}
                                            onChange={handleChange}
                                            onKeyDown={handleKeyDown}
                                            onKeyUp={(event) => handleKeyUp(event, onEnter)}
                                            tabIndex={0}
                                        />
                                        <label
                                            htmlFor={`${elementId}_${option.value}`}
                                            key={`${elementId}_${option.value}_label`}
                                        >
                                            {option.title}
                                        </label>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <p
                        id={`${elementId}_error`}
                        className="error"
                    >
                        {error && error.show && error.validationMessage}
                    </p>
                </div>
            </div>
        </div>
    );
});

export default CheckboxInput;
