import IGeneric from "../interfaces/IGeneric";
import IMenu from "../interfaces/IMenu";
import IMenuItem from "../interfaces/IMenuItem";

import "../styles/Layout.css";
import Home from "../pages/Home";
import { clientAppConfig } from "../pages/AuthProvider";
import Login from "../components/Login";
import Menu from "../components/Menu";

import React, { useState, useRef } from "react";
import { useSearchParams, useLocation } from "react-router-dom";
import "preline/preline";
import { IStaticMethods } from "preline/preline";

declare global {
    interface Window {
        HSStaticMethods: IStaticMethods;
    }
}

const Layout: React.FC = () => {
    const { requiresAuthentication, clientAppName, workflowResourcegroupName, workflowName } = clientAppConfig;
    const defaultWorkflow = workflowResourcegroupName + "/" + workflowName;

    const [searchParams] = useSearchParams();
    const { hash } = useLocation();

    const getQueryStringParams: IGeneric = () => {
        let query: IGeneric = Object.fromEntries([...searchParams]);
        if (hash && /^#\/\?/.test(hash)) {
            hash.replace(/^#\/\?/, '')
                .split('&')
                .reduce((params, param) => {
                    const [key, value] = param.split('=');
                    query = { ...query, [key]: value }
                    return { [key]: value };
                }, {});
            window.location.hash = "";
        }
        return query;
    };

    const [topMenu, setTopMenu] = useState<IMenu>();
    const [sideMenu, setSideMenu] = useState<IMenu>();
    const [menuItem, setMenuItem] = useState<IMenuItem>();
    const [resumeHeader, setResumeHeader] = useState<IGeneric>(getQueryStringParams);
    const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);

    resumeHeader.clientUrl = window.location.origin;

    const menusCallback = (menus: IGeneric | undefined) => {
        setTopMenu(menus?.top);
        setSideMenu(menus?.side);
    }

    const loginCallback = (loggedIn: boolean) => {
        setIsLoggedIn(loggedIn);
        if (!loggedIn)
            setResumeHeader(getQueryStringParams);
    }

    const mainRef = useRef<HTMLElement>(null);
    const chatView = process.env.REACT_APP_CHAT_VIEW === "true";

    return (
        <div
            id={chatView ? "layout" : "nochat-layout"}
        >
            <header>
                <div>
                    <div
                        id="logo"
                    >
                        <img
                            src={process.env.REACT_APP_LOGO_URL}
                            alt={`${clientAppName} Logo`}
                            title={`${clientAppName} Logo`}
                        />
                    </div>
                    {requiresAuthentication &&
                        <div
                            id="login"
                        >
                            <Login menusCallback={menusCallback} loginCallback={loginCallback} />
                        </div>
                    }
                </div>
                {(!requiresAuthentication || isLoggedIn) && topMenu &&
                    <Menu type="top" menuItems={topMenu.menuItems} header={topMenu.header} menuItemCallback={setMenuItem} />
                }
            </header>
            <main
                id="main"
                ref={mainRef}
            >
                {(!requiresAuthentication || isLoggedIn) && sideMenu &&
                    <Menu type="side" menuItems={sideMenu.menuItems} header={sideMenu.header} menuItemCallback={setMenuItem} />
                }
                {(!requiresAuthentication || isLoggedIn) &&
                    <Home
                        mainRef={mainRef}
                        resumeHeader={resumeHeader}
                        menuItem={menuItem}
                        menusCallback={menusCallback}
                        loginCallback={loginCallback}
                    />
                }
                {requiresAuthentication && !isLoggedIn &&
                    <>
                        <div
                            id="requiresAuthentication"
                        >
                            <div>
                                Please sign in using your Microsoft account
                            </div>
                            <Login menusCallback={menusCallback} loginCallback={loginCallback} />
                        </div>
                    </>
                }
            </main>
            <footer id="footer">
                <span>Powered by</span>
                <img
                    src="/prinsix-logo.svg"
                    alt="PrinSIX logo"
                />
            </footer>
        </div>
    );
};

export default Layout;
