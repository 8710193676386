import "./styles/App.css";
import Layout from "./components/Layout";
import AuthProvider from "./pages/AuthProvider";
function App() {

    return (
        <AuthProvider>
            <Layout />
        </AuthProvider>
    );
}

export default App;
