import IGeneric from "../interfaces/IGeneric";

import "../styles/SelectInput.css";
import Label from "./Label";

import { mutliValidator } from "../utils/validators";
import { getCurrentOptions } from "../utils/mutate";

import React, { useEffect, useState } from "react";
import HSSelect from "@preline/select";

export const SelectInput: React.FC = ((props: IGeneric) => {
    const { type, attributes, elementId, onStateChange, validations, onError, error, options, containerRef, inputRef } = props;

    const { value, label, tooltip, multiple, searchable, noWrap } = attributes;

    const defaultValues: string[] | undefined | null = options.filter((option: IGeneric) => option.default).map((option: IGeneric) => option.value);

    const [inputValue, setInputValue] = useState<string[] | undefined | null>(value !== undefined ? (Array.isArray(value) ? value : [value]) : defaultValues);

    const defaultValue = multiple ? (inputValue ?? []) : ((inputValue && inputValue.at(0)) ?? options.at(0).value);

    const validate = (selectedValues: string[] | undefined | null, show: boolean) => {
        const validationMessage = mutliValidator(
            selectedValues,
            type,
            validations,
            attributes,
        );

        if (validationMessage)
            onError({ validationMessage, show });
        else {
            if (Array.isArray(selectedValues) && !attributes.multiple)
                onStateChange(selectedValues.length > 0 ? selectedValues.at(0) : null);
            else
                onStateChange(selectedValues);
            setInputValue(selectedValues);
            onError(undefined);
        }
        return validationMessage;
    };


    useEffect(() => {
        validate(defaultValue, false);
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (inputRef.current) {
            const hsSelect = HSSelect.getInstance(inputRef.current as HTMLElement) as HSSelect;
            if (hsSelect) {
                hsSelect.on("change", (value: string | string[]) => validate(multiple ? value as string[] : [value as string], true));
            }
        }
    });

    return (
        <div className={`input-container select-input ${noWrap}`} id={`${elementId}_container`} ref={containerRef}>
            <div>
                <div className={error && error.show ? "error-select" : ""}>
                    <Label
                        elementId={elementId}
                        content={label}
                        tooltip={tooltip}
                    />
                    <select
                        id={elementId}
                        name={elementId}
                        defaultValue={defaultValue}
                        multiple={multiple}
                        unselectable="on"
                        data-hs-select={`{
                                ${searchable === true ? '"hasSearch": true, "searchPlaceholder": "Search...", "searchClasses": "search",' : ''}
                                "placeholder": "Select option...",
                                "toggleTag": "<button type='button' tabIndex='0'></button>",
                                "toggleClasses": "hs-select-disabled:pointer-events-none hs-select-disabled:opacity-50 toggle",
                                "dropdownClasses": "dropdown",
                                "optionClasses": "option",
                                "optionTemplate": "<div class='option-template'><span data-title></span><span class='hidden hs-selected:block'><svg xmlns='http:.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'><polyline points='20 6 9 17 4 12'/></svg></span></div>",
                                "extraMarkup": "<div class='select-svg'><svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'><path d='m7 15 5 5 5-5'/><path d='m7 9 5-5 5 5'/></svg></div>"
                              }`}
                        ref={inputRef}
                    >
                        {
                            getCurrentOptions(options, inputValue).map((option: IGeneric) => {
                                return (
                                    <option
                                        key={elementId + "_" + option.value}
                                        id={elementId + "_" + option.value}
                                        value={option.value}
                                    >
                                        {option.title}
                                    </option>
                                )
                            })
                        }
                    </select>
                    <p
                        id={`${elementId}_error`}
                        className="error"
                    >
                        {error && error.show && error.validationMessage}
                    </p>
                </div>
            </div>
        </div>
    );
});

export default SelectInput;
