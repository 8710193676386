import React, { ReactNode } from "react";
import { PublicClientApplication, EventMessage, EventType, AuthenticationResult } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { ClientAppConfig } from "../utils/ClientAppConfig";

interface IAuthProviderProps {
    children: ReactNode;
}

export const clientAppConfig = new ClientAppConfig(
    process.env.REACT_APP_API_VERSION,
    process.env.REACT_APP_API_DOMAIN
);

export const msalInstance = await clientAppConfig.initialize().then(() => clientAppConfig.requiresAuthentication ? new PublicClientApplication({
        auth: {
            clientId: clientAppConfig.ssoAppId,
            authority: `https://${process.env.REACT_APP_LOGIN_AUTHORITY}/${clientAppConfig.tenantId}`,
            redirectUri: `${process.env.REACT_APP_API_DOMAIN ? `https://${process.env.REACT_APP_API_DOMAIN}` : ``}/api/ui/auth`,
        },
        cache: {
            cacheLocation: "sessionStorage", // or "localStorage"
            storeAuthStateInCookie: false,
        },
    }) : undefined
);

const AuthProvider: React.FC<IAuthProviderProps> = ({ children }) => {
    if (msalInstance) {
        msalInstance.initialize().then(() => {

            const accounts = msalInstance.getAllAccounts();
            if (accounts.length > 0) {
                msalInstance.setActiveAccount(accounts[0]);
            }

            msalInstance.addEventCallback((event: EventMessage) => {
                if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
                    const payload = event.payload as AuthenticationResult;
                    msalInstance.setActiveAccount(payload.account);
                }
            });
        });

        return (
            <MsalProvider instance={msalInstance}>
                {children}
            </MsalProvider>
        );
    }
    else {
        return (
            <>
                {children}
            </>
        );
    }
};

export default AuthProvider;
